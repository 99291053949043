<template>
  <!-- Render a div element with dynamic id and height -->
  <div :id="element" :style="'height: ' + height + 'px;'"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4geodataWorldLow from "@amcharts/amcharts4-geodata/worldLow";
import { mapGetters } from "vuex";
import _ from "lodash";
import constant, { decryptToken } from "@/config/constant";
import axios from "axios";

am4core.useTheme(am4themes_animated);

export default {
  name: "AmChart",
  props: {
    // Props received from parent component
    element: { type: String, default: "am-chart" },
    type: { type: String, default: "bar" },
    option: { type: Object, default: () => ({}) },
    height: { type: Number, default: 500 },
    selectedCountryName: { type: Array, default: () => [] },
    selectedCountryPublicID: { type: Array, default: () => [] },
    selectedLatitude: { type: Array, default: () => [] },
    selectedLongitude: { type: Array, default: () => [] },
    websitePublicID: String,
    firstCountriesPublicId: String,
  },
  computed: {
    // Compute properties from Vuex store getters
    ...mapGetters({
      darkMode: "Setting/darkModeState",
    }),
  },
   mounted() {
    
    // Mounting lifecycle hook
    if (this.type === "dashboard-map") {
      this.createMapChart(); // Call createMapChart() to initialize the chart
    } else {
      this.createChart();
    }
    
    // Set interval to call fetchLatencies every 5 minute (300000 milliseconds)
    this.interval = setInterval(this.fetchLatencies, 300000);
      
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed to prevent memory leaks
    clearInterval(this.interval);
  },

  watch: {
    // Watchers for changes in selected country data
    selectedCountryName: {
      handler: "debouncedUpdateChart", // Call debouncedUpdateChart() on change
      deep: true,
    },
    selectedCountryPublicID: {
      handler: "debouncedUpdateChart",
      deep: true,
    },
    selectedLatitude: {
      handler: "debouncedUpdateChart",
      deep: true,
    },
    selectedLongitude: {
      handler: "debouncedUpdateChart",
      deep: true,
    },
    // Watch for changes in websitePublicID
    selectedCountryPublicID(newVal, oldVal) {
      if (newVal !== oldVal && this.websitePublicID) {
        // Call handleCountryClick with the new selected country's public ID
        if (newVal.length > 0) {
          this.handleCountryClick(newVal[0], this.websitePublicID);
        } else {
          console.error("Selected Country Public ID is missing.");
        }
      }
    },
  },

  data() {
    // Data properties
    return {
      latitude: this.selectedLatitude,
      longitude: this.selectedLongitude,
      currentSelectedCountryPublicID: null,
    };
  },
  methods: {
async fetchLatencies() {
  try {
    // Retrieve token from local storage
    const token = localStorage.getItem("token");
    const key = "enc"; // Encryption key
    // Decrypt token
    const decryptedToken = decryptToken(token, key);

    // Check if a country is selected
    if (this.currentSelectedCountryPublicID && this.websitePublicID) {
      // Fetch latencies data for the current selected country
      const latenciesResponse = await axios.get(
        constant.API_BASE_URL +
          "web-monitoring/page-load-time/fetch_latencies/?website_public_id=" +
          this.websitePublicID +
          "&country_public_id=" +
          this.currentSelectedCountryPublicID,
        {
          headers: { Authorization: "Token " + decryptedToken },
        }
      );
      const responseData = latenciesResponse.data.data[0];

      if (responseData) {
        const countryName = responseData.key;
        const latencies = responseData.data.map((entry) => entry.latency);
        const latency_time = responseData.data.map((entry) => entry.time);

        this.eventData = {
          name: countryName,
          data: latencies,
          latency_time: latency_time,
        };
        this.$emit("overAlllatency", {
          overAlllatency: this.eventData,
        });
      } else {
        this.eventData = {
          name: null,
          data: null,
          latency_time: null,
        };
        // Clear the graph data if no new data is available
        // this.eventData = null;
        this.$emit("overAlllatency", {
          overAlllatency: this.eventData,
        });
      }
    }
  } catch (error) {
    this.eventData = {
          name: null,
          data: null,
          latency_time: null,
        };
        // Clear the graph data if no new data is available
        // this.eventData = null;
        this.$emit("overAlllatency", {
          overAlllatency: this.eventData,
        });
  }
  finally {
        this.loading = false;
      }
},


    async handleCountryClick(publicId, websitePublicID) {
      try {
        
        this.currentSelectedCountryPublicID = publicId;
        this.fetchLatencies();
        // Retrieve token from local storage
        const token = localStorage.getItem("token");
        const key = "enc"; // Encryption key
        // Decrypt token
        const decryptedToken = decryptToken(token, key);

        // Fetch overall uptime data
        const overall_uptime_response = await axios.get(
          constant.API_BASE_URL +
            "web-monitoring/page-load-time/overall_uptime/?website_public_id=" +
            websitePublicID +
            "&country_public_id=" +
            publicId,
          {
            headers: { Authorization: "Token " + decryptedToken },
          }
        );

        let uptime_data = overall_uptime_response.data.data["uptime_data"];
        let dates = [];
        let uptimes = [];
        if (Array.isArray(uptime_data) && uptime_data.length > 0) {
          uptime_data.forEach((item) => {
            if (item.date !== "average_percentage") {
              dates.push(item.time);
              uptimes.push(item.uptime_percentage);
            }
          });
        }
        
        // Emit event with overall uptime data
        this.$emit("overallData", {
          overAllUpTime: overall_uptime_response.data.data["24hrs_overall_uptime"],
          dates: dates,
          uptimes: uptimes,
        });

        const server_response_time_result = await axios.get(
          constant.API_BASE_URL +
            "web-monitoring/page-load-time/server_response_time/?website_public_id=" +
            websitePublicID +
            "&country_public_id=" +
            publicId,
          {
            headers: { Authorization: "Token " + decryptedToken },
          }
        );

        if (server_response_time_result.data.data) {
          // If data is available, assign it to variables
          this.serverResponseTime = server_response_time_result.data.data.server_response_time;
        } else {
          // If data is not available, set variables to 'N/A'
          this.serverResponseTime = 'N/A';
        }
        this.$emit("serverResponseTime", this.serverResponseTime);

        const page_load_time_result = await axios.get(
          constant.API_BASE_URL +
            "web-monitoring/page-load-time/get_webpage_load/?website_public_id=" +
            websitePublicID +
            "&country_public_id=" +
            publicId,
          {
            headers: { Authorization: "Token " + decryptedToken },
          }
        );
        if (page_load_time_result.data && page_load_time_result.data.data) {
          this.pageLoadTime = page_load_time_result.data.data;
          this.$emit("pageLoadTime", this.pageLoadTime);
        } 
        else{
          this.pageLoadTime = '';
          this.$emit("pageLoadTime", this.pageLoadTime);
        
        }
   
      } catch (error) {
        this.pageLoadTime = '';
          this.$emit("pageLoadTime", this.pageLoadTime);
      }
      finally {
        this.loading = false;
      }
    },
    // Debounced method to update chart after a delay

    // Debounced method to update chart after a delay
    debouncedUpdateChart: _.debounce(function () {
      this.updateChart();
    }, 200),
    // Create chart based on type prop

    // Create map chart
    createMapChart() {
      this.chart = am4core.create(this.element, am4maps.MapChart);
      this.map(this.chart);
    },
    // Update chart based on type prop
    updateChart() {
      if (this.type === "dashboard-map") {
        this.map(this.chart);
      } else {
        this.createChart();
      }
    },
    // Map chart creation and configuration
    map(chart) {
      
      am4core.useTheme(am4themes_animated);
      chart.geodata = am4geodataWorldLow;
      chart.projection = new am4maps.projections.Miller();
      // Add polygon series for countries
      var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
      polygonSeries.exclude = ["AQ"];
      polygonSeries.useGeodata = true;
      var polygonTemplate = polygonSeries.mapPolygons.template;
      polygonTemplate.fill = "#585858";
      polygonTemplate.tooltipText = "{name}";
      polygonTemplate.polygon.fillOpacity = 0.6;

      
      var hs = polygonTemplate.states.create("hover");
      hs.properties.fill = chart.colors.getIndex(0);

      // Add image series for plotting points on map
      var imageSeries = chart.series.push(new am4maps.MapImageSeries());
      imageSeries.mapImages.template.propertyFields.longitude = "longitude";
      imageSeries.mapImages.template.propertyFields.latitude = "latitude";
      imageSeries.mapImages.template.tooltipText = "{title}";
      imageSeries.mapImages.template.propertyFields.url = "url";

      // Add circles to map images
      let circle = imageSeries.mapImages.template.createChild(am4core.Circle);
      circle.radius = 3;
    //  ********************************************************

      let circle2 = imageSeries.mapImages.template.createChild(am4core.Circle);
      circle2.radius = 3;
      circle2.propertyFields.fill = "color";
      circle2.events.on("inited", (event) => {
        this.animateBullet(event.target);
      });

      let colorSet = new am4core.ColorSet();
      imageSeries.data = [];

      // Remove existing map images
      imageSeries.mapImages.each((mapImage) => {
        mapImage.dispose();
      });

      // Add map images for selected countries
      this.selectedCountryName.forEach((country, index) => {
        circle.fill = index === 0 ? am4core.color("blue") : am4core.color("green");
        circle2.fill = index === 0 ? am4core.color("blue") : am4core.color("green");

        if (
          this.selectedLatitude[index] !== undefined &&
          this.selectedLongitude[index] !== undefined
        ) {
          // Add data to imageSeries only if latitude and longitude values are available
          
          const mapImage = imageSeries.mapImages.create();
          mapImage.latitude = parseFloat(this.selectedLatitude[index]);
          mapImage.longitude = parseFloat(this.selectedLongitude[index]);
          mapImage.tooltipText = country;

          let baseRadius = 4;
          // Create circle 1
          let circle = mapImage.createChild(am4core.Circle);
          circle.radius = baseRadius;
          circle.fill = index === 0 ? am4core.color("blue") : am4core.color("green");

          // Create circle 2
          let circle2 = mapImage.createChild(am4core.Circle);
          circle2.radius = baseRadius;
          circle2.fill = index === 0 ? am4core.color("blue") : am4core.color("green");
          circle2.events.on("inited", (event) => {
              this.animateBullet(event.target);
          });
          // Listen to zoom level changes
        chart.events.on("zoomlevelchanged", function(event) {
            // Retrieve the zoom level from the event
            let currentZoomLevel = event.target.zoomLevel;

            // Check if currentZoomLevel is valid (not undefined or null)
            if (currentZoomLevel !== undefined && currentZoomLevel !== null) {
                if (currentZoomLevel > 1) {
                
                    // Calculate the scale based on the zoom level
                    let newScale = 1 / currentZoomLevel;

                    // Set the scale of the circles to maintain the visual appearance
                    circle.scale = newScale;
                    circle2.scale = newScale;

                    // Set the radius of the circles
                    circle.radius = baseRadius * newScale;
                    circle2.radius = baseRadius * newScale;

                    // Make circle fill transparent
                    circle.fillOpacity = 0;
                } else {               
                    // If zoom level is 1 or less, revert to the original size
                    circle.scale = 1;
                    circle2.scale = 1;
                    circle.radius = baseRadius;
                    circle2.radius = baseRadius;
                    circle.fillOpacity = 1; // Set fill opacity back to 1 for visibility
                }             
            }
        });

          // Attach click event to map image
          // Attach click event to map image
mapImage.events.on("hit", (event) => {
    // Reset color of all circles to green
    imageSeries.mapImages.each((mapImage) => {
        let currentCircle = mapImage.children.getIndex(0);
        let currentCircle2 = mapImage.children.getIndex(1);
        let currentLabel = mapImage.children.getIndex(2);
        
        // Check if the current mapImage is the clicked one
        if (mapImage === event.target) {
            // Change color to blue for the clicked country
            currentCircle.fill = am4core.color("blue");
            currentCircle2.fill = am4core.color("blue");
            currentLabel.fill = am4core.color("blue");
            this.animateBullet(currentCircle2);
            circle2.fill = am4core.color("blue");
        } else {
            // Change color to green for other countries
            currentCircle.fill = am4core.color("green");
            currentCircle2.fill = am4core.color("green");
            currentLabel.fill = am4core.color("green"); // Assuming default label color is black


        }
    });

    // Handle country click
    const clickedCountry = event.target.tooltipText;
    const publicId = this.selectedCountryPublicID[index];
    const websitePublicID = this.websitePublicID;
    this.handleCountryClick(publicId, websitePublicID);
    
});

          
          // Set label color to black
          const label = mapImage.createChild(am4core.Label);
          label.text = country;
          label.fill = am4core.color("#000000"); // Set label color to black
          label.fontSize = 12;
          label.wrap = true;
          label.truncate = false;
        }
      });
      
    },
    // Animate bullet on map
    animateBullet(circle) {
      var animation = circle.animate(
        [
          { property: "scale", from: 1, to: 5 },
          { property: "opacity", from: 1, to: 0 },
        ],
        1000,
        am4core.ease.circleOut
      );
      animation.events.on("animationended", (event) => {
        this.animateBullet(event.target.object);
      });
    },
  },
};
</script>
